// import router from "../../router/index"
// https://dev.api.rapidrent.com/venues?zip=75075
import { getFutureDate, formatDates, GetDates, MonthNumber, MonthAsString, DayAsString, formatDateTime, formatTimeSlots, convertPreferredTime } from "../helpers/Dates"
import axios from 'axios'
import * as moment from 'moment'
export const namespaced = true

// STATE
export const state = {

    currentIndex: -1,
    apiData: null,
    dataRes: null,
    confirmation: null,
    pick_up_rental: null,
    rental_start_return: null,
    rental_return: null,
    cart: [], //CART
    customer: {},

    tabs: [
        [],
        [{ name: 'MACHINE', href: 'products' }, { name: 'ATTACHMENT', href: 'attachments' }, { name: 'SOLUTION', href: 'solutions' }, { name: 'CHECKOUT', href: 'cart' },],
        [{ name: 'CART', href: 'cart' }, { name: 'CHECKOUT', href: 'checkout' }, { name: 'RECEIPT', href: 'confirmation' },],
        [{ name: 'RENTAL LOOKUP', href: 'lookup' }, { name: 'RETURN RENTAL', href: 'return' }, { name: 'RECEIPT', href: 'receipt-return' },],

    ],
    currentTabRow: 0,
    currentTabIndex: 0,
    selectionForm: {
        machine: null,
    },
    calendarToggle: false,
    machineList: [],
    bays: null,
    toolList: [],
    solutionList: [],
    bundleList: [],

    jdeNumber: null,
    kiosk_id: null,
    affiliate_id: null,
    skuPriceMatrix: {},
    kiosk: null,
    user_form: null,

    rentalCategory: null,
    attachmentCategory: null,
    solutionCategory: null,
    rentalCategories: null,
    attachmentCategories: null,
    solutionCategories: null,

}

export const mutations = {
    SET_USER_FORM(state, form) {
        state.user_form = form
    },
    //API
    SET_AFFILIATE(state, id) {
        state.affiliate_id = id
    },
    SET_APIFORM(state, data) {
        state.dataRes = data
    },
    SET_CONFIRMATION(state, data) {
        state.confirmation = data
    },
    //CUSTOMER
    SET_CUSTOMER(state, customer) {
        state.customer = customer
    },
    //TAB CONTROL
    SET_TABS(state, tabs) {
        state.tabs = tabs
    },
    SET_CURRENTINDEX(state, currentIndex) {
        state.currentIndex = currentIndex
    },

    //FORM
    SET_FORM(state, selectionForm) {
        state.selectionForm = selectionForm
    },

    // CART ACTIONS
    SET_CART(state, cart) {
        state.cart = cart
    },
    ADD_ITEM(state, newItem) {
        const checkedItemIndex = state.cart.indexOf(state.cart.find(item => item?.id === newItem?.id))
        const solutionIndex = state.solutionList.indexOf(state.cart.find(item => item?.id === newItem?.id))
        if (checkedItemIndex >= 0) { // item is found
            if (newItem.quantity == 0 || newItem.quantity == undefined) {
                newItem.quantity = 1
            }
            state.cart[checkedItemIndex].quantity = newItem.quantity
        }
        else {
            if (newItem.quantity == 0 || newItem.quantity == undefined) {
                newItem.quantity = 1
            }
            state.cart.push(newItem)
        }
        if (solutionIndex >= 0) {
            state.solutionList[solutionIndex].quantity = newItem.quantity
            state.solutionList[solutionIndex].selected = true
        }

    },
    DEL_ITEM(state, item) {

        const itemIndex = state.cart.indexOf(state.cart.find(e => e.id === item.id))
        const solutionListIndex = state.solutionList.indexOf(state.solutionList.find(e => e.id === item.id))

        if (itemIndex >= 0) {
            if (item.quantity == 0) {
                state.cart.splice(itemIndex, 1)
            } else {
                state.cart[itemIndex].quantity = item.quantity
            }
        }
        if (solutionListIndex >= 0) {
            if (item.quantity == 0) {
                state.solutionList[solutionListIndex].quantity = 0
                state.solutionList[solutionListIndex].selected = false
            } else {
                state.solutionList[solutionListIndex].quantity = item.quantity
            }

        }
    },
    SET_MACHINE(state, machine) {
        // state.cart = state.cart.filter(item => item?.product_group == 'solution' || item?.product_group == 'machine')
        state.cart.unshift(machine)
    },
    UPDATE_BAY(state, data) {
        let machine = data?.machine
        let bay = data?.bay
        const findMachineListIndex = state.machineList.indexOf(state.machineList.find(e => e?.id === machine?.id))
        const findMachineCartIndex = state.cart.indexOf(state.cart.find(e => e?.id === machine?.id))
        if (findMachineCartIndex >= 0) {
            state.cart[findMachineCartIndex].bay_number = bay?.bay_number
            state.cart[findMachineCartIndex].bay_id = bay?.bay_id
            state.cart[findMachineCartIndex].selected = true
        }
        if (findMachineListIndex >= 0) {
            state.machineList[findMachineListIndex].bay_number = bay?.bay_number
            state.machineList[findMachineListIndex].bay_id = bay?.bay_id
            state.machineList[findMachineListIndex].selected = true
        }
    },
    UPDATE_MACHINE_LIST(state, machine) {
        const findMachineListIndex = state.machineList.indexOf(state.machineList.find(e => e?.id === machine?.id))
        if (findMachineListIndex >= 0) {
            state.machineList[findMachineListIndex].bay_number = null
            state.machineList[findMachineListIndex].bay_id = null
            state.machineList[findMachineListIndex].selected = false
        }
    },
    DESELECT_MACHINE(state, machine) {

        const findIndex = state.cart.indexOf(state.cart.find(e => e?.id === machine?.id))
        const findMachineListIndex = state.machineList.indexOf(state.machineList.find(e => e?.id === machine?.id))
        if (findIndex >= 0) {
            state.cart.splice(findIndex, 1)
            let tools = state.cart.filter(product => product?.product_group === "attachment") ?? []
            let machines = state.cart.filter(product => product?.product_group === "machine") ?? []
            if (machines.length == 0) {
                state.cart = state.cart.filter(item => item?.product_group == 'solution') ?? []
            }
            else if (tools.length > 0) {
                for (let i = 0; i < tools.length; i++) {
                    let tool = tools[i]
                    let temp = null
                    for (let l = 0; l < machines.length; l++) {
                        let curr = machines[l]
                        let related_products = curr.related_products ?? []
                        temp = related_products.find((e) => e?.id === tool?.id)
                        if (temp) { break }
                    }
                    if (!temp) {
                        const findToolIndex = state.cart.indexOf(state.cart.find(e => e?.id === tool?.id))
                        if (findToolIndex >= 0) {
                            state.cart.splice(findToolIndex, 1)
                        }
                    }

                }
            }
        }
        if (findMachineListIndex >= 0) {
            state.machineList[findMachineListIndex].bay_number = null
            state.machineList[findMachineListIndex].bay_id = null
            state.machineList[findMachineListIndex].selected = false
        }
    },
    UPDATE_TOOL_LIST(state, machine) {
        const toolIndex = state.toolList.indexOf(state.toolList.find(e => e.id === tool.id))
        if (state.toolList[toolIndex].selected) {
            state.toolList[toolIndex].selected = false
        }
    },
    DESELECT_TOOL(state, tool) {

        const findIndex = state.cart.indexOf(state.cart.find(e => e?.id === tool?.id))
        const findToolListIndex = state.toolList.indexOf(state.toolList.find(e => e?.id === tool?.id))
        if (findIndex >= 0) {
            if (findToolListIndex >= 0) {
                state.toolList[findToolListIndex].selected = false
            }
            state.cart.splice(findIndex, 1)
        }
    },
    SELECT_TOOL(state, tool) {
        // state.cart = state.cart.filter(item => item?.product_group == 'solution' || item?.product_group == 'machine')
        state.cart.push(tool)
    },
    SET_CURRENT_TAB_ROW(state, index) {
        state.currentTabRow = index
    },
    SET_CURRENT_TAB_INDEX(state, index) {
        state.currentTabIndex = index
    },
    SET_KIOSK(state, kiosk) {
        state.kiosk = kiosk
        state.kiosk_id = kiosk?.id
        state.cart = []
        state.selectionForm = {
            machine: null,
        }
    },
    SET_MACHINES(state, machineList) {
        state.machineList = machineList
    },
    SET_BAYS(state, bays) {
        state.bays = bays
    },
    SET_TOOLS(state, toolList) {
        state.toolList = toolList
    },
    SET_SOLUTIONS(state, solutionList) {
        state.solutionList = solutionList
    },
    SET_PICKUP_RENTAL(state, data) {
        state.pick_up_rental = data
    },
    SET_START_RENTAL_RETURN(state, data) {
        console.log('state rental_start_return ', data)
        state.rental_return = data
    },
    SET_RENTAL_RETURN(state, data) {
        state.rental_return = data
    },
    RESET_COMPLETE(state) {
        state.cart = []
        state.confirmation = null
        state.apiData = null
        state.dataRes = null
        state.pick_up_rental = null
        state.rental_return = null
        state.bays = null
        state.rentalCategory = null
        state.attachmentCategory = null
        state.solutionCategory = null
        state.rentalCategories = null
        state.attachmentCategories = null
        state.solutionCategories = null
        state.selectionForm = {
            machine: null,
        }
        state.machineList = []
        state.bays = null
        state.toolList = []
        state.solutionList = []
        state.bundleList = []

        state.jdeNumber = null
        state.kiosk_id = null
        state.affiliate_id = null
        state.kiosk = null

    },
    RESET_FORM(state) {
        state.cart = []
        // state.confirmation = null
        state.apiData = null
        state.dataRes = null
        state.bays = null
        state.rentalCategory = null
        state.attachmentCategory = null
        state.solutionCategory = null
        // rentalCategories = null
        // attachmentCategories = null
        // solutionCategories = null
        state.selectionForm = {
            machine: null,
        }
    },
    SET_CATEGORY(state, form) {
        let categoryType = form.type
        let category = form.category
        state[categoryType] = category
    },
    SET_CATEGORIES(state, form) {
        let categoryType = form.type
        let categories = form.categories
        state[categoryType] = categories
    },
}

export const actions = {
    async setUserForm({ commit, dispatch }, form) {
        commit('SET_USER_FORM', form)
    },
    async setKiosk({ commit, dispatch }, kiosk) {
        commit("SET_KIOSK", kiosk)
    },
    async setMachineList({ commit, dispatch }, machineList) {
        commit("SET_MACHINES", machineList)
    },
    async setBays({ commit, dispatch }, bays) {
        commit("SET_BAYS", bays)
    },
    async setToolList({ commit, dispatch }, toolList) {
        commit("SET_TOOLS", toolList)
    },
    async setSolutionsList({ commit, dispatch }, solutionList) {
        commit("SET_SOLUTIONS", solutionList)
    },
    //CART
    async setCart({ commit, dispatch }, cart) {
        commit("SET_CART", cart)
    },
    async updateMachineList({ commit, dispatch }, item) {
        commit("UPDATE_MACHINE_LIST", item)
    },
    async updateBay({ commit, dispatch }, data) {
        commit("UPDATE_BAY", data)
    },
    async updateToolList({ commit, dispatch }, item) {
        commit("UPDATE_TOOL_LIST", item)
    },
    async addItem({ commit, dispatch }, item) {
        commit("ADD_ITEM", item)
    },
    async delItem({ commit, dispatch }, item) {
        commit("DEL_ITEM", item)
    },
    async setMachine({ commit, dispatch }, machine) {
        commit("SET_MACHINE", machine)
    },
    async deselectMachine({ commit, dispatch }, machine) {
        commit("DESELECT_MACHINE", machine)
    },
    async selectTool({ commit, dispatch }, tool) {
        commit("SELECT_TOOL", tool)
    },
    async deselectTool({ commit, dispatch }, tool) {
        commit("DESELECT_TOOL", tool)
    },

    //TABS
    async setTabs({ commit, dispatch }, tabs) {
        commit("SET_TABS", tabs)
    },

    async setCurrentTabRow({ commit, dispatch }, index) {
        commit("SET_CURRENT_TAB_ROW", index)
    },
    async setCurrentTabIndex({ commit, dispatch }, index) {
        commit("SET_CURRENT_TAB_INDEX", index)
    },
    //FORM
    async setForm({ commit, dispatch }, selectionForm) {
        commit("SET_FORM", selectionForm)
    },

    async resetForm({ commit, dispatch }) {
        commit("RESET_FORM")
    },
    async resetComplete({ commit, dispatch }) {
        commit("RESET_COMPLETE")
    },

    async setAffiliate({ commit, dispatch }, id) {
        commit("SET_AFFILIATE", id)
    },

    //API
    async setApiData({ commit, dispatch }, data) {
        commit('SET_APIDATA', data)
    },
    async setPublishForm({ commit, dispatch }, form) {
        commit('SET_CONFIRMATION', form)
    },
    async setAPIForm({ commit, dispatch }, form) {
        commit('SET_APIFORM', form)
    },
    async setCustomer({ commit, dispatch }, customer) {
        commit('SET_CUSTOMER', customer)
    },
    async setPickUpRental({ commit, dispatch }, data) {
        commit('SET_PICKUP_RENTAL', data)
    },
    async setStartRentalReturn({ commit, dispatch }, data) {
        commit('SET_START_RENTAL_RETURN', data)
    },
    async setRentalReturn({ commit, dispatch }, data) {
        commit('SET_RENTAL_RETURN', data)
    },
    async setCategory({ commit, dispatch }, form) {
        commit('SET_CATEGORY', form)
    },
    async setCategories({ commit, dispatch }, form) {
        commit('SET_CATEGORIES', form)
    }




}

export const getters = {
    // API
    affiliateId: (state) => {
        return state.affiliate_id
    },
    apiData: (state) => {
        return state.apiData
    },
    dataRes: (state) => {
        return state.dataRes
    },
    // CART
    cart: state => {
        return state.cart
    },
    // TABS
    tabs: (state) => {
        return state.tabs
    },
    currentIndex: (state) => {
        return state.currentIndex
    },
    currentTabRow: (state) => {
        return state.currentTabRow
    },
    currentTabIndex: (state) => {
        return state.currentTabIndex
    },
    // FORMS
    form: (state) => {
        return state.selectionForm
    },

    // ITEMS
    machineList: (state) => {
        return state.machineList
    },
    bays: (state) => {
        return state.bays
    },
    solutionList: (state) => {
        return state.solutionList
    },

    toolList: (state) => {
        return state.toolList
    },
    bundleList: (state) => {
        return state.bundleList
    },


    // CUSTOMER
    customer: (state) => {
        return state.customer
    },
    // CONFIRMATION
    confirmation: (state) => {
        return state.confirmation
    },
    jdeNumber: (state) => {
        return state.jdeNumber
    },
    kiosk_id: (state) => {
        return state.kiosk_id
    },
    kiosk: (state) => {
        return state.kiosk
    },
    user_form: (state) => {
        return state.user_form
    },
    pick_up_rental: (state) => {
        return state.pick_up_rental
    },
    start_rental_return: (state) => {
        return state.start_rental_return
    },
    rental_return: (state) => {
        return state.rental_return
    },
    rentalCategory: (state) => {
        return state.rentalCategory
    },
    attachmentCategory: (state) => {
        return state.attachmentCategory
    },
    solutionCategory: (state) => {
        return state.solutionCategory
    },
    rentalCategories: (state) => {
        return state.rentalCategories
    },
    attachmentCategories: (state) => {
        return state.attachmentCategories
    },
    solutionCategories: (state) => {
        return state.solutionCategories
    },
}


