import Vue from 'vue'
import { createApp, } from 'vue'
import router from './router/index'
import store from './store/index'
import { createMetaManager } from 'vue-meta'
import App from './components/App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import LitepieDatepicker from 'litepie-datepicker';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
    apiKey: 'a125077d1bf400891c0993218a27ad7b',
    plugins: [new BugsnagPluginVue()],
  })
const bugsnagVue = Bugsnag.getPlugin('vue')
const app = createApp(App)
    .use(bugsnagVue)
    .use(router)
    .use(store)
    .use(VueAxios, axios)
    .use(createMetaManager())
    .use(LitepieDatepicker);

// app.mount('#app')
router.isReady().then(() => {
    app.mount('#app')
})

export default app
