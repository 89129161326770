import { createWebHistory, createRouter } from 'vue-router'
import middlewarePipeline from './middlewarePipline'
import {generateRoutes} from "./routes/routes"
const routes = [
    ...generateRoutes(),
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {
            top: 0
        }
    },
})

router.beforeEach((to, from, next) => {
    const middleware = to.meta.middleware
    const context = { to, from, next }

    if (!middleware) {
        return next()
    }
    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    })
})

export default router
