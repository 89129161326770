// import auth from '../../middleware/auth'
export const generateRoutes = () => {
    return [
        {
            path: '/rack',
            name: 'rack',

            component: () => import('../../pages/JDE.vue'),
        },
        {
            path: '/welcome',
            name: 'welcome',

            component: () => import('../../pages/Welcome.vue'),
        },
        {
            path: '/products',
            name: 'products',

            component: () => import('../../pages/Products.vue'),
        },
        {
            path: '/attachments',
            name: 'attachments',

            component: () => import('../../pages/Attachments.vue'),
        },
        {
            path: '/solutions',
            name: 'solutions',

            component: () => import('../../pages/Sellables.vue'),
        },
        {
            path: '/cart',
            name: 'cart',

            component: () => import('../../pages/Cart.vue'),
        },
        {
            path: '/checkout',
            name: 'checkout',

            component: () => import('../../pages/Checkout.vue'),
        },
        {
            path: '/confirmation',
            name: 'confirmation',

            component: () => import('../../pages/Confirmation.vue'),
        },
        {
            path: '/lookup',
            name: 'lookup',

            component: () => import('../../pages/Lookup.vue'),
        },
        {
            path: '/return',
            name: 'return',

            component: () => import('../../pages/Return.vue'),
        },
        {
            path: '/receipt',
            name: 'receipt',

            component: () => import('../../pages/Return-Receipt.vue'),
        },
        {
            path: '/:jdeNumber',
            name: 'Start',

            props: true,
            component: () => import('../../components/App.vue'),
        },
    ]
}
