<template>
    <Spinner v-if="loading" :showSpinner="loading" />
    <div class='h-screen w-screen h100dvh flex flex-col overflow-hidden lato'>
        <div class="h10 flex p-4 w-full bg-white z-10">
            <div id="RD-Logo" class="object-contain mb-0 mx-auto pl-1" @click.prevent="handleCurrentMenu('welcome')">
                <img alt="Rug Doctor Logo" :class="[(currentTabIndex === 0 && currentTabRow == 0) ? 'rd-logo-main' : 'rd-logo', 'mb-0 mx-auto ']" class="" :src="logo" >
            </div>
        </div>
        <div class="h90 flex flex-col">
            <div v-if="tabs && tabs[currentRow].length > 0" class='flex w-full bg-white h8'>
                <div :class="[(currentRow == 2 || currentRow == 3) ? 'nav-padding' : '', 'w-full h-full flex flex-row justify-between px-2 z-10']" class="" id="nav-indicator">
                    <!-- <transition-group name="tab-list"></transition-group> -->
                    <div v-for="(tab, index) in tabs[currentRow]" :key="tab.name" :class="[currentRow == 1 ? 'nav-tab w-1/5' : 'nav-return-tab w-1/4', 'relative flex flex-col cursor-pointer  my-auto']">
                        <div v-if="tab?.href == 'cart'">
                            <div @click="handleTabClick(currentRow, index)" :class="[index === currentTabIndex ? '' : ' ', ' flex h-[30px] w-[30px] md:h-[60px] md:w-[60px] mx-auto hover:cursor-pointer transform duration-700 ease-in-out  relative text-center rounded-full md:rounded-lg bg-white']">
                                <div :class="[index === currentTabIndex ? '' : '', 'mx-auto mt-auto  flex text-center rd_fontsize-8 md:text-rd_fontsize-28']">
                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" class="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                                        </svg> -->
                                    <svg xmlns="http://www.w3.org/2000/svg" strokeWidth={0.5} stroke="currentColor" class="hidden md:flex md:h-12 md:w-12" viewBox="0 0 29 29" fill="none">
                                        <path d="M7.97648 28.22C7.37795 28.22 6.8768 28.0126 6.47302 27.5977C6.06925 27.1829 5.86737 26.6722 5.86737 26.0656C5.86737 25.4591 6.07182 24.9511 6.48072 24.5416C6.8896 24.1321 7.39312 23.9274 7.9913 23.9274C8.58948 23.9274 9.09561 24.1348 9.50969 24.5496C9.9238 24.9645 10.1309 25.4752 10.1309 26.0817C10.1309 26.6883 9.92343 27.1963 9.50859 27.6058C9.09373 28.0153 8.58302 28.22 7.97648 28.22ZM22.2075 28.22C21.609 28.22 21.1078 28.0126 20.7041 27.5977C20.3003 27.1829 20.0984 26.6722 20.0984 26.0656C20.0984 25.4591 20.3028 24.9511 20.7117 24.5416C21.1206 24.1321 21.6241 23.9274 22.2223 23.9274C22.8205 23.9274 23.3266 24.1348 23.7407 24.5496C24.1548 24.9645 24.3619 25.4752 24.3619 26.0817C24.3619 26.6883 24.1544 27.1963 23.7396 27.6058C23.3247 28.0153 22.814 28.22 22.2075 28.22ZM5.51745 4.19059L9.80426 13.1841H19.9934C20.1684 13.1841 20.3288 13.1404 20.4746 13.0529C20.6204 12.9654 20.7322 12.8439 20.8099 12.6883L25.053 5.0071C25.1697 4.79324 25.1794 4.60369 25.0822 4.43845C24.9849 4.27321 24.8197 4.19059 24.5864 4.19059H5.51745ZM4.90505 3.02408H24.6396C25.2984 3.02408 25.794 3.29432 26.1263 3.8348C26.4587 4.37525 26.4732 4.9303 26.1699 5.49994L21.9665 13.1747C21.7398 13.5328 21.4532 13.8184 21.1066 14.0313C20.76 14.2442 20.3929 14.3506 20.0052 14.3506H9.06644L7.00176 18.1475C6.86569 18.3808 6.86569 18.6335 7.00176 18.9057C7.13786 19.1779 7.35171 19.314 7.64332 19.314H24.3619V20.4804H7.80956C6.96193 20.4804 6.34807 20.1752 5.96798 19.5647C5.58792 18.9543 5.58937 18.2942 5.97234 17.5846L8.4278 13.1695L2.72372 1.16647H0V0H3.47317L4.90505 3.02408Z" fill="#313131" />
                                    </svg>
                                    <svg class="md:hidden" xmlns="http://www.w3.org/2000/svg" width="27" height="29" viewBox="0 0 27 29" fill="none">
                                        <path d="M7.97648 28.22C7.37795 28.22 6.8768 28.0126 6.47302 27.5977C6.06925 27.1829 5.86737 26.6722 5.86737 26.0656C5.86737 25.4591 6.07182 24.9511 6.48072 24.5416C6.8896 24.1321 7.39312 23.9274 7.9913 23.9274C8.58948 23.9274 9.09561 24.1348 9.50969 24.5496C9.9238 24.9645 10.1309 25.4752 10.1309 26.0817C10.1309 26.6883 9.92343 27.1963 9.50859 27.6058C9.09373 28.0153 8.58302 28.22 7.97648 28.22ZM22.2075 28.22C21.609 28.22 21.1078 28.0126 20.7041 27.5977C20.3003 27.1829 20.0984 26.6722 20.0984 26.0656C20.0984 25.4591 20.3028 24.9511 20.7117 24.5416C21.1206 24.1321 21.6241 23.9274 22.2223 23.9274C22.8205 23.9274 23.3266 24.1348 23.7407 24.5496C24.1548 24.9645 24.3619 25.4752 24.3619 26.0817C24.3619 26.6883 24.1544 27.1963 23.7396 27.6058C23.3247 28.0153 22.814 28.22 22.2075 28.22ZM5.51745 4.19059L9.80426 13.1841H19.9934C20.1684 13.1841 20.3288 13.1404 20.4746 13.0529C20.6204 12.9654 20.7322 12.8439 20.8099 12.6883L25.053 5.0071C25.1697 4.79324 25.1794 4.60369 25.0822 4.43845C24.9849 4.27321 24.8197 4.19059 24.5864 4.19059H5.51745ZM4.90505 3.02408H24.6396C25.2984 3.02408 25.794 3.29432 26.1263 3.8348C26.4587 4.37525 26.4732 4.9303 26.1699 5.49994L21.9665 13.1747C21.7398 13.5328 21.4532 13.8184 21.1066 14.0313C20.76 14.2442 20.3929 14.3506 20.0052 14.3506H9.06644L7.00176 18.1475C6.86569 18.3808 6.86569 18.6335 7.00176 18.9057C7.13786 19.1779 7.35171 19.314 7.64332 19.314H24.3619V20.4804H7.80956C6.96193 20.4804 6.34807 20.1752 5.96798 19.5647C5.58792 18.9543 5.58937 18.2942 5.97234 17.5846L8.4278 13.1695L2.72372 1.16647H0V0H3.47317L4.90505 3.02408Z" fill="#313131" />
                                    </svg>
                                    <div class="relative">
                                        <div v-if="cart?.length > 0 && currentRow == 2 && currentCol >= 1" class="absolute -top-2 rd-cart-icon open_sans flex h-[16px] w-[16px] bg-rd_red text-white rounded-full text-rd_fontsize-9 font-semibold open_sans">
                                            <svg class="absolute rd-tab-check2" xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 16 13" fill="none">
                                                <path d="M5.2355 12.1681L0 6.92463L1.59927 5.33333L5.2355 8.96957L14.213 0L15.8043 1.5913L5.2355 12.1681Z" fill="#FEFEFE" />
                                            </svg>
                                        </div>
                                        <div v-else-if="cart?.length > 0" class="absolute -top-2 rd-cart-icon open_sans flex h-[16px] w-[16px] bg-rd_red text-white rounded-full text-rd_fontsize-9 font-semibold open_sans">
                                            <div class="m-auto">{{ getCartTotal() }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="[index === currentTabIndex ? '' : '', 'flex flex-col text-center em4 rd-tab-name text-bissell_blue gb']">
                                {{ tab.name }}
                            </div>
                        </div>
                        <div v-else>

                            <div v-if="index < currentTabIndex" @click="handleTabClick(currentRow, index)" :class="[index === currentTabIndex ? '' : '', ' flex  h-[30px] w-[30px] md:h-[60px] md:w-[60px] mx-auto hover:cursor-pointer transform duration-700 ease-in-out px-2 relative text-center border border-black rounded-full  bg-rd_red text-white']">
                                <div :class="[index === currentTabIndex ? '' : '', 'm-auto  flex text-center rd_fontsize-8 md:text-rd_fontsize-28']">
                                    <svg class="rd-tab-check" xmlns="http://www.w3.org/2000/svg" width="15.8" height="12.1" viewBox="0 0 16 13" fill="none">
                                        <path d="M5.2355 12.1681L0 6.92463L1.59927 5.33333L5.2355 8.96957L14.213 0L15.8043 1.5913L5.2355 12.1681Z" fill="#FEFEFE" />
                                    </svg>
                                </div>
                            </div>
                            <div v-else @click="handleTabClick(currentRow, index)" :class="[index === currentTabIndex ? 'bg-white text-rd_red' : 'bg-black text-white', 'flex h-[30px] w-[30px] md:h-[60px] md:w-[60px] mx-auto hover:cursor-pointer transform duration-700 ease-in-out px-2 relative text-center border border-black rounded-full ']">
                                <div :class="[index === currentTabIndex ? '' : '', 'm-auto  flex text-center rd-tab-number']">
                                    {{ index + 1 }}
                                </div>
                            </div>
                            <div :class="[index === currentTabIndex ? '' : '', 'flex  flex-col text-center em4 rd-tab-name text-bissell_blue gb']">
                                {{ tab.name }}
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div :class="[(tabs && tabs[currentRow].length > 0) ? 'h92' : 'h-full', 'flex flex-col  w-full']">
                <RouterView :tabs="tabs" :handleTabs="handleTabs" :setMountedTab="setMountedTab" :updateCart="updateCart" :selectionForm="selectionForm" :machineList="machineList" :bays="bays" :toolList="toolList" :solutionList="solutionList" :cart="cart" :productData="productData" :dataRes="dataRes" :customer="customer" :confirmation="confirmation" :currentIndex="currentIndex" :nextTab="nextTab" :setCurrentTab="setCurrentTab" :serviceType="serviceType" :kiosk_id="kiosk_id" :kiosk="kiosk" :mobileView="mobileView" :affiliateId="affiliateId" :loading="loading" :handleConfirmationPage="handleConfirmationPage" :user_form="user_form" :setUserForm="setUserForm" :handleSpinner="handleSpinner" :handleCurrentMenu="handleCurrentMenu" :currentMenu="currentMenu" :jumpToSolutions="jumpToSolutions" :startRentalProcess="startRentalProcess" :changeJDE="changeJDE" :jdeError="jdeError" :returnError="returnError" :pick_up_rental="pick_up_rental" :handlePickUp="handlePickUp" :handleCategory="handleCategory" :rentalCategory="rentalCategory" :attachmentCategory="attachmentCategory" :solutionCategory="solutionCategory" :rentalCategories="rentalCategories" :attachmentCategories="attachmentCategories" :solutionCategories="solutionCategories" :machineSelection="machineSelection" :setLoading="setLoading" :startCheckout="startCheckout" :findRentalReturn="findRentalReturn" :rental_return="rental_return" :returnRentals="returnRentals" :completeReturn="completeReturn" :submitPayment="submitPayment" :reloadKiosk="reloadKiosk" :reloadMainMenu="reloadMainMenu" :handleReceiptPage="handleReceiptPage" :setReturnError="setReturnError" />
            </div>
        </div>

    </div>
</template>
<script>
import { useStore, mapGetters, mapActions } from 'vuex'
import { ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import Spinner from '../pages/modals/Spinner.vue'
import _ from "lodash"
import axios from 'axios'
import logo_img from '../assets/RDBissellLogo.png'
export default {
    components: {
        Spinner,
    },
    setup() {
        // H1 001E4B Quasimoda Bold - Title Case - BISSELL BLUE
        // H2 001E4B Quasimoda Bold - Title Case - BISSELL
        // H3 001E4B Quasimoda Bold - Title Case - BISSELL BLUE
        // • H4 001E4B Quasimoda Bold - Title Case - BISSELL BLUE
        // Paragraph 001E4B Gotham Book - BISSELL
        // Links: 001E4B Gotham Book Bold - ALL CAPS - UNDERLINDED - BISSELL
        // ACTIVE CTA BUTTON: DA1C15 Gotham Book Bold - ALL CAPS
        // Button color - Red (DA1C15)
        // Button text color - White
        // Square shape with rounded corners
        const route = useRoute()
        const store = useStore()
        const selectionForm = computed(() => store.getters['checkout/form'])
        const tabs = computed(() => store.getters['checkout/tabs'])

        const cart = computed(() => store.getters['checkout/cart'])
        const productData = computed(() => store.getters['checkout/apiData'])

        const machineList = computed(() => store.getters['checkout/machineList'])


        const solutionList = computed(() => store.getters['checkout/solutionList'])
        const toolList = computed(() => store.getters['checkout/toolList'])

        const dataRes = computed(() => store.getters['checkout/dataRes'])
        const customer = computed(() => store.getters['checkout/customer'])

        const confirmation = computed(() => store.getters['checkout/confirmation'])

        const currentIndex = computed(() => store.getters['checkout/currentIndex'])

        const mobileView = window.innerWidth >= 640 ? ref(false) : ref(true)


        const currentTabRow = computed(() => store.getters['checkout/currentTabRow'])
        const currentTabIndex = computed(() => store.getters['checkout/currentTabIndex'])

        const jdeNumber = computed(() => store.getters['checkout/jdeNumber'])
        const kiosk_id = computed(() => store.getters['checkout/kiosk_id'])

        const affiliateId = computed(() => store.getters['checkout/affiliateId'])

        const serviceType = (location.hostname.includes('rugdoctordelivery.com') || location.hostname.includes('delivery.rugdoctor.com') || location.hostname.includes('rugdoctorhomedelivery')) ? 'delivery' : 'reservation'

        const firstSolution = ref({})

        const kiosk = computed(() => store.getters['checkout/kiosk'])
        const user_form = computed(() => store.getters['checkout/user_form'])

        const pick_up_rental = computed(() => store.getters['checkout/pick_up_rental'])
        const start_rental_return = computed(() => store.getters['checkout/start_rental_return'])
        const rental_return = computed(() => store.getters['checkout/rental_return'])
        const bays = computed(() => store.getters['checkout/bays'])
        const currentMenu = ref('main')

        let rentalCategory = computed(() => store.getters['checkout/rentalCategory'])
        let attachmentCategory = computed(() => store.getters['checkout/attachmentCategory'])
        let solutionCategory = computed(() => store.getters['checkout/solutionCategory'])
        let rentalCategories = computed(() => store.getters['checkout/rentalCategories'])
        let attachmentCategories = computed(() => store.getters['checkout/attachmentCategories'])
        let solutionCategories = computed(() => store.getters['checkout/solutionCategories'])

        return {
            route,
            store,
            selectionForm,
            tabs,

            cart,
            productData,

            machineList,
            solutionList,
            toolList,

            customer,
            confirmation,
            mobileView,
            dataRes,


            currentIndex,

            currentTabRow,
            currentTabIndex,

            serviceType,
            jdeNumber,
            kiosk_id,

            affiliateId,
            firstSolution,

            currentMenu,
            kiosk,
            user_form,
            pick_up_rental,
            start_rental_return,
            rental_return,
            bays,
            rentalCategory,
            attachmentCategory,
            solutionCategory,
            rentalCategories,
            attachmentCategories,
            solutionCategories,
        }
    },

    data() {
        let currentRow = this.currentTabRow ?? 0
        let currentCol = this.currentTabIndex ?? 0
        let logo = logo_img
        return {
            currentRow: currentRow,
            currentCol: currentCol,
            loading: false,
            jdeError: false,
            returnError: false,
            returnRentals: [],
            rental_data: null,
            logo: logo,
        }
    },
    mounted() {
        console.log('mounted start_rental_return', this.start_rental_return)
        console.log('mounted rental_return', this.rental_return)
        let changeMenu = true
        const logo = document.querySelectorAll('#RD-Logo')
        if (logo && logo.length == 2 && logo[1]?.style) {
            logo[1].style.display = 'none'
        }
        window.addEventListener('resize', this.onResize)
        try {
            const route = this.$route
            let route_path = route?.path ?? ""
            if (route_path.includes("kiosk")) {
                const match = route_path.match(/\/kiosk\/(\d+)/);
                if (match) {
                    const number = match[1];
                    changeMenu = false
                    this.getKioskInfo(number)
                }
            }
            else if (route?.params?.jdeNumber) {
                changeMenu = false
                this.getKioskInfo(route.params.jdeNumber)
            }
        }
        catch (e) {

        }

        // this.checkCurrentTab()

        setTimeout(() => {
            if (changeMenu && !this.kiosk?.id) {
                this.handleCurrentMenu('rack')
            }
        }, '1000')

    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
    computed: {

    },
    methods: {
        ...mapActions('checkout', [
            'setTabs', 'setApiData', 'setCurrentTabIndex', 'setCurrentTabRow', 'setAffiliate', 'setKiosk', 'setMachineList', 'setToolList', 'setSolutionsList', 'setUserForm', 'setPickUpRental', 'setStartRentalReturn', 'setRentalReturn', 'setBays', 'setCategory', 'setCategories', 'setMachine', 'setCart', 'resetComplete'
        ]),
        onResize() {
            if (window.innerWidth < 640) {
                this.mobileView = true
            }
            else if (window.innerWidth >= 640) {
                this.mobileView = false
            }
        },
        checkCurrentTab() {
            const route = this.$route
        },
        handleMainMenu() {
            this.currentRow = 0
            this.currentCol = 0
            this.$store.dispatch('checkout/setCurrentTabRow', 0)
            this.$store.dispatch('checkout/setCurrentTabIndex', 0)
        },
        handleMainMenu() {
            this.currentRow = 0
            this.currentCol = 0
            this.$store.dispatch('checkout/setCurrentTabRow', 0)
            this.$store.dispatch('checkout/setCurrentTabIndex', 0)
        },
        setMountedTab(row, col) {
            let currentRow = this.currentTabRow
            let currentCol = this.currentTabIndex
            if (row !== currentRow || col !== currentCol) {
                this.currentRow = row
                this.currentCol = col
                this.$store.dispatch('checkout/setCurrentTabRow', row)
                this.$store.dispatch('checkout/setCurrentTabIndex', col)
            }
        },
        tabValidation(row, col) {
            //     'main': 0,
            //     'rental': 1,
            //     'checkout': 2,
            //     'return': 3,
            return true
        },
        handleTabClick(row, col) {
            let tabVal = true
            if (row != 1) {
                if (row == 2) {
                    if (col == 1) {
                        tabVal = false
                    } else if (col >= 2) {
                        tabVal = false
                    }
                }
                else if (row == 3) {
                    tabVal = false
                    if (col == 1) {
                        tabVal = false
                    } else if (col >= 2) {
                        tabVal = false
                    }
                }
            }

            if (tabVal) {
                console.log(tabVal)
                this.currentRow = row
                this.currentCol = col
                const tabs = this.tabs
                const tab = tabs?.length > 0 ? tabs[row][col] : null
                this.$store.dispatch('checkout/setCurrentTabRow', row)
                this.$store.dispatch('checkout/setCurrentTabIndex', col)

                if (tab?.href) {
                    this.$router.push({ name: tab.href })
                }
            }
        },
        handleTabs(row, col) {

            if (this.tabValidation(row, col)) {
                this.currentRow = row
                this.currentCol = col
                const tabs = this.tabs
                const tab = tabs?.length > 0 ? tabs[row][col] : null
                this.$store.dispatch('checkout/setCurrentTabRow', row)
                this.$store.dispatch('checkout/setCurrentTabIndex', col)

                if (tab?.href) {
                    this.$router.push({ name: tab.href })
                }
            }
        },
        nextTab() {
            const nextTabSelected = this.tabs[this.currentTabIndex + 1]
            if (nextTabSelected?.href) {
                this.$store.dispatch('checkout/setCurrentTabIndex', (this.currentTabIndex + 1))
                this.$router.push({ name: nextTabSelected.href })
            }
        },
        setCurrentTab(index) {

            const nextTabSelected = this.tabs[index]
            if (nextTabSelected?.href) {
                this.$store.dispatch('checkout/setCurrentTabIndex', index)
                this.$router.push({ name: nextTabSelected.href })
            }
        },

        changeJDE(jde) {
            this.getKioskInfo(jde)
        },
        startRentalProcess() {
            if (!this.kiosk?.id) {
                this.handleMainMenu()
                this.handleCurrentMenu('rack')
            } else {
                this.currentMenu = 'rent'
                this.handleTabs(1, 0)
            }
        },
        startCheckout() {

            this.currentMenu = 'rent'
            this.handleTabs(2, 0)

        },
        startReturnProcess() {
            if (!this.kiosk?.id) {
                this.handleMainMenu()
                this.handleCurrentMenu('rack')
            } else {
                this.currentMenu = 'lookup'
                this.handleTabs(3, 0)
            }
        },
        handleCurrentMenu(option) {
            if (option == 'welcome') {
                this.handleMainMenu()
                this.$router.push({ name: 'welcome' })
            }
            else if (option == 'rack') {
                this.handleMainMenu()
                this.$router.push({ name: 'rack' })
            }
            else if (option == 'lookup') {
                this.startReturnProcess()

            }
            this.currentMenu = option
        },
        machineSelection(item) {
            this.$store.dispatch("checkout/setMachine", item)
        },
        setUserForm(data) {
            let form = { ...data }
            form.cardNumber = ''
            form.expDate = ''
            form.cvc = ''

            this.$store.dispatch('checkout/setUserForm', form)
        },
        jumpToSolutions() {
            this.currentMenu = 'rent'
            this.handleTabs(1, 2)
        },
        formatSellables(resp) {
            let solutionList = []
            let solutionCategories = {}
            let data = resp?.products_available_at_kiosk
            if (Array.isArray(data) && data.length > 0) {
                data.forEach(product => {
                    if (product.product_group === "solution" && product.price?.retail_price) {
                        product.price = product.price?.retail_price
                        if (product?.category) {
                            solutionCategories[product.category] = product.category
                        }
                        product.selected = false
                        product.quantity = 0
                        solutionList.push(product)
                    }
                })
                let findIndex1 = solutionList.findIndex(item => item?.sku_number == "05044")

                if (findIndex1 > 0) {

                    solutionList.unshift(solutionList.splice(findIndex1, 1)[0])
                }
                let findIndex2 = solutionList.findIndex(item => item?.sku_number == "05045")
                if (findIndex2 > 0) {
                    solutionList.unshift(solutionList.splice(findIndex2, 1)[0])
                }
            }
            this.$store.dispatch('checkout/setSolutionsList', solutionList)
            this.$store.dispatch('checkout/setCategories', { type: 'solutionCategories', categories: solutionCategories })
            // this.$store.dispatch('checkout/setCategories', { type: 'solutionCategories', categories: { 'Indoor': 'Indoor', 'Outdoor': 'Outdoor' } })
        },
        formatRentalData(data) {
            let jde_number = data[0]?.jde_number
            let kiosk = data[0]
            let kiosk_id = kiosk?.id
            if (jde_number && kiosk_id) {

                let machineList = []
                let toolList = []
                let rentalCategories = {}
                let toolCategories = {}
                const bays = data[0]?.bays ?? []
                let machineBays = {}
                if (bays.length == 0) {
                    this.jdeError = true
                    return
                }
                const availableBays = bays.filter(bay => bay?.products_in_bay)
                if (availableBays.length > 0) {
                    availableBays.forEach(bay => {
                        if (bay?.active && Array.isArray(bay?.products_in_bay) && bay?.occupancy > 0) {
                            bay.products_in_bay.forEach(product => {
                                let productObj = product?.product_type
                                let price = product?.price?.retail_price
                                if (product?.product_type?.product_group === "machine" && bay?.bay_available_dates?.length > 0 && productObj?.sku_number) { // machines
                                    if (!machineBays[productObj.sku_number]) {
                                        machineBays[productObj.sku_number] = []
                                    }
                                    if (productObj?.category) {
                                        rentalCategories[productObj.category] = productObj.category
                                    }
                                    // productObj.bay_number = bay.number
                                    productObj.bay_number = null
                                    productObj.price = price ? price : product.product_type?.base_price?.retail_price
                                    productObj.bay_id = null
                                    productObj.selected = false
                                    productObj.kiosk_id = kiosk_id
                                    productObj.bay_available_dates = bay?.bay_available_dates
                                    machineBays[productObj.sku_number].push({ bay_id: bay?.id, bay_number: bay?.number, state: bay?.state, })

                                    machineList.push(productObj)

                                }
                                else if (product.product_type?.product_group === "attachment" && bay.state === 'available') { //tools
                                    productObj.bay_number = bay.number
                                    productObj.price = price ? price : product.product_type?.base_price?.retail_price
                                    productObj.kiosk_id = kiosk_id
                                    productObj.selected = false
                                    if (productObj?.category) {
                                        toolCategories[productObj.category] = productObj.category
                                    }
                                    toolList.push(productObj)
                                }
                            })
                        }
                    })
                }
                if (machineList.length > 0) {
                    machineList = machineList.reduce((acc, current) => {
                        const x = acc.find(item => item?.id === current?.id)
                        if (!x) {
                            return acc.concat([current])
                        } else {
                            return acc
                        }
                    }, [])
                    let findIndex = machineList.findIndex(item => item?.sku_number == "09200")
                    if (findIndex >= 0) {

                        machineList.unshift(machineList.splice(findIndex, 1)[0])
                    }
                }
                if (toolList.length > 0) {
                    toolList = toolList.reduce((acc, current) => {
                        const x = acc.find(item => item?.id === current?.id)
                        if (!x) {
                            return acc.concat([current])
                        } else {
                            return acc
                        }
                    }, [])
                }

                this.$store.dispatch('checkout/setKiosk', kiosk)
                this.$store.dispatch('checkout/setMachineList', machineList)
                this.$store.dispatch('checkout/setBays', machineBays)
                this.$store.dispatch('checkout/setToolList', toolList)
                this.$store.dispatch('checkout/setCategories', { type: 'rentalCategories', categories: rentalCategories })
                this.$store.dispatch('checkout/setCategories', { type: 'attachmentCategories', categories: { 'Indoor': 'Indoor', 'Outdoor': 'Outdoor' } })

                if (kiosk?.chemicals_sell) {
                    this.getSellables(kiosk_id)
                }
                if (kiosk?.rent_type === 'self_checkout') {
                    const titleName = document.querySelector('title')
                    if (titleName) {
                        document.title = "Rug Doctor Self Checkout"
                    }
                }
                this.handleCurrentMenu('welcome')

            } else {
                this.jdeError = true
            }
        },
        reloadKiosk() {
            let kiosk = this.kiosk
            let jde_number = this.kiosk?.jde_number
            if (jde_number) {
                const apiUrl1 = process.env.MIX_API_URL + '/kiosks/?jde_number=' + jde_number
                const requestOne = axios({
                    url: apiUrl1,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'text/plain'
                    },

                })
                axios.all([requestOne]).then(axios.spread((...responses) => {
                    const responseOne = responses[0]?.data
                    if (responseOne) {
                        this.formatRentalData(responseOne)
                    }
                })).catch(errors => {
                    console.log(errors)
                })
            }
        },
        async getKioskInfo(jde) {
            if (typeof jde == 'number') {
                jde = jde.toString()
            }
            let jde_number = jde
            const apiUrl1 = process.env.MIX_API_URL + '/kiosks/?exact_jde_number=' + jde_number
            this.loading = true
            this.jdeError = false
            const requestOne = axios({
                url: apiUrl1,
                method: 'GET',
                headers: {
                    'Content-Type': 'text/plain'
                },

            })
            this.$store.dispatch('checkout/resetComplete')
            axios.all([requestOne]).then(axios.spread((...responses) => {
                const responseOne = responses[0]?.data

                if (responseOne) {
                    if (responseOne && responseOne[0]?.rent_type == 'home_delivery') {
                        this.jdeError = true
                    } else {
                        this.formatRentalData(responseOne)
                    }

                }
                this.loading = false
            })).catch(errors => {
                console.log(errors)
                this.jdeError = true
                this.loading = false
            })
        },
        async getSellables(id) {
            const apiUrl1 = process.env.MIX_API_URL + '/venues/products-available-at-kiosk?' + 'id=' + id
            this.loading = true
            const requestOne = axios({
                url: apiUrl1,
                method: 'GET',
                headers: {
                    'Content-Type': 'text/plain'
                },
            })

            axios.all([requestOne]).then(axios.spread((...responses) => {
                const responseOne = responses[0]
                if (responseOne?.data) {
                    this.formatSellables(responseOne.data)
                }
                this.loading = false
            })).catch(errors => {
                console.log(errors)
                this.loading = false
            })
        },
        handleSpinner(show) {
            this.loading = show
        },
        handleCategory(categoryType, category) {
            const form = {
                type: categoryType,
                category: category
            }
            this.$store.dispatch('checkout/setCategory', form)
        },
        handleCategories(categoryType, categories) {
            const form = {
                type: categoryType,
                categories: categories
            }
            this.$store.dispatch('checkout/setCategories', form)
        },
        handleConfirmationPage() {
            this.$router.push({ path: 'confirmation' })
        },
        handleRentalReturnPage() {
            this.$router.push({ path: 'return' })
        },
        handlePickUp() {
            // this.$router.push({ path: 'RESERVATIONPAGE' })
            this.pickUp()
        },
        setLoading(option) {
            this.loading = option
        },
        async submitPayment(form) {
            this.errorMsg = null
            let apiUrl = process.env.MIX_API_URL + '/rentals/'
            try {
                this.submitLock = true
                const resp = await axios.put(apiUrl + form.rental_id + '/publish', form)

                if (resp?.data) {
                    this.loading = false
                    this.$store.dispatch('checkout/setPublishForm', resp.data)
                    this.handleConfirmationPage()
                } else {
                    this.errorMsg = 'Unabled to process your order at this time.'
                    this.loading = false
                }

            }
            catch (err) {
                console.error('error', err)
                let errorMsg = err.response?.data?.message ?? null
                if (errorMsg) {
                    this.errorMsg = errorMsg
                }
                else {
                    this.errorMsg = 'Unabled to process your order at this time.'
                }
                this.loading = false
                return errorMsg
            }
        },
        async pickUp(form) {
            this.errorMsg = null
            let apiUrl = process.env.MIX_API_URL + '/rentals/'
            try {
                this.loading = true
                const resp = await axios.put(apiUrl + form.rental_id + '/pick_up_products')
                if (resp?.data) {
                    this.loading = false
                    this.$store.dispatch('checkout/setPickUpRental', resp.data)
                    this.$store.dispatch('checkout/resetForm')
                    this.handleConfirmationPage()

                } else {
                    this.loading = false
                    this.errorMsg = 'Please contact customer support to pickup your rental'

                }

            }
            catch (err) {
                console.error('error', err)
                let errorMsg = err.response?.data?.message ?? null
                if (errorMsg) {
                    this.errorMsg = errorMsg
                }
                else {
                    this.errorMsg = 'Please contact customer support to pickup your rental'
                }
                this.loading = false
            }
        },
        async findRentalReturn(params) {

            this.errorMsg = null
            this.returnError = false
            let apiUrl = process.env.MIX_API_URL + '/rentals?'
            const kiosk_id = this.kiosk_id
            if (!kiosk_id) { return }
            try {
                this.loading = true
                const resp = await axios.get(apiUrl + 'kiosk_id=' + kiosk_id, {

                    params: params
                })
                if (resp?.data) {
                    const data = resp.data
                    if (!Array.isArray(data) || data.length == 0) {
                        this.returnError = true
                        this.loading = false
                    } else {
                        console.log('findRentalReturn', data[0])
                        this.$store.dispatch('checkout/setRentalReturn', data[0])
                        this.handleReturnStart(data[0])
                    }

                } else {
                    this.loading = false
                    this.returnError = true
                    this.errorMsg = 'Please contact customer support to return your rental'

                }
            }
            catch (err) {
                console.error('error', err)
                let errorMsg = err.response?.data?.message ?? null
                if (errorMsg) {
                    this.errorMsg = errorMsg
                }
                else {
                    this.errorMsg = 'Please contact customer support to return your rental'
                }
                this.returnError = true
                this.loading = false
            }
        },
        handleReceiptPage() {
            this.$router.push({ name: 'receipt' })
        },
        async handleReturnStart(data) {
            this.errorMsg = null
            let apiUrl = process.env.MIX_API_URL + '/rentals/'
            let rental_id = data?.id
            try {
                const resp = await axios.put(apiUrl + rental_id + '/start_return_products', {})
                if (resp?.data) {
                    this.rental_data = resp.data
                    this.$store.dispatch('checkout/setStartRentalReturn', resp.data)
                    this.loading = false
                    this.handleRentalReturnPage()
                } else {
                    this.returnError = true
                    this.loading = false
                }

            }
            catch (err) {
                console.error('error', err)
                let errorMsg = err.response?.data?.message ?? null
                this.returnError = true
                this.loading = false
            }
        },
        async completeReturn() {
            let data = this.rental_data ? this.rental_data : (this.rental_return ? this.rental_return : null)
            this.errorMsg = null
            let apiUrl = process.env.MIX_API_URL + '/rentals/'
            let rental_id = data?.id
            try {
                const resp = await axios.put(apiUrl + rental_id + '/return_products', {})
                if (resp?.data) {
                    this.$store.dispatch('checkout/setRentalReturn', null)
                    this.$store.dispatch('checkout/setStartRentalReturn', null)
                    this.$store.dispatch('checkout/setPickUpRental', null)
                    this.loading = false
                    this.handleReceiptPage()
                    this.rental_data = []
                } else {
                    this.returnError = true
                    this.loading = false
                    // this.errorMsg = 'Please contact customer support to return your rental'
                }

            }
            catch (err) {
                console.error('error', err)
                let errorMsg = err.response?.data?.message ?? null
                this.returnError = true
                this.loading = false
            }
        },
        updateCart(cart) {
            this.$store.dispatch('checkout/setCart', cart)
        },
        getCartTotal() {
            let count = 0
            const cart = this.cart
            for (let i = 0; i < cart.length; i++) {
                let item = cart[i]
                let quantity = item?.quantity ?? 1
                count += quantity
            }
            return count
        },
        reloadMainMenu() {
            this.handleCurrentMenu('welcome')
            let jde_number = this.kiosk?.jde_number
            if (jde_number) {
                this.getKioskInfo(jde_number)
            }
        },
        setReturnError(value) {
            this.returnError = value
        },
    }

}
</script>
